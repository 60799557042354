@font-face {
  src: url("everett.393ded3f.otf");
  font-family: everett;
}

@font-face {
  src: url("digi.d95cd214.TTF");
  font-family: digi;
}

@font-face {
  src: url("berthold.693bcb57.ttf");
  font-family: berthold;
}

:root {
  --color--white: color(0, 0, 0);
  --color--gray: color(200, 200, 200);
  --color--black: color(255, 255, 255);
}

@keyframes continue {
  40% {
    transform: translateX(-15px);
  }

  90% {
    transform: translateX(-12px);
  }

  100% {
    transform: translateX(0);
  }
}

body {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: url("dark.b84f17b1.jpg") 50% / cover;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}

::-webkit-scrollbar {
  display: none;
}

.animation_text {
  color: #c8c8c8;
  text-align: center;
  font-family: everett;
  font-size: 3vw;
}

.header_container {
  width: 50vw;
  height: auto;
  z-index: 99;
  font-family: everett;
  font-size: 2.8vw;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.header {
  width: 50%;
  color: #c8c8c8;
  padding-top: 4.5vw;
  padding-left: 5vw;
}

.header_desktop {
  width: 50%;
  color: #c8c8c8;
  padding-top: 40px;
  padding-left: 45px;
  font-size: 20px;
  display: none;
}

.transform {
  -o-transition: all 2s ease;
  transition: all 2s;
}

.transform-active {
  transform: translateX(2vw);
}

.section {
  text-align: center;
  font-family: everett;
  font-size: 4vw;
}

.slide {
  text-align: center;
}

.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  flex-wrap: nowrap;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.card {
  width: auto;
  max-height: 100vh;
  height: -webkit-fill-available;
  flex: none;
}

#matches_cover {
  width: auto;
  height: 100%;
  transform: rotate(-10deg);
}

.equation_small {
  text-indent: 0;
  font-family: everett;
  font-size: 3vw;
}

.text {
  text-align: left;
  text-indent: 10vw;
  padding-top: 4.5vw;
  padding-left: 5vw;
  padding-right: 5vw;
  font-size: 4vw;
}

.text_paradigm {
  color: #fff;
  opacity: .8;
}

.equation {
  padding-left: 15vw;
}

img {
  width: 100%;
  height: auto;
  padding-top: 0;
  left: 0;
}

.continue_animation {
  will-change: transform;
  animation-name: continue;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 7s;
}

.images_50_wrapper {
  width: 50vw;
  box-sizing: border-box;
  float: left;
  padding-top: 4.5vw;
  padding-left: 5vw;
  padding-right: 2.5vw;
}

.images_50_wrapper_small {
  width: 35vw;
  box-sizing: border-box;
  float: left;
  margin-right: 15vw;
  padding-top: 4.5vw;
  padding-left: 5vw;
  padding-right: 2.5vw;
}

.images_50_wrapper_right {
  width: 50vw;
  box-sizing: border-box;
  float: left;
  padding-top: 4.5vw;
  padding-left: 2.5vw;
  padding-right: 5vw;
}

.s3_images_50 {
  max-width: 100%;
  width: auto;
  height: auto;
}

.s3_footnotes {
  text-align: left;
  padding-left: 5vw;
  font-size: 3vw;
}

.s3_footnotes_50 {
  width: 100%;
  text-align: left;
  font-size: 3vw;
}

.s3_footnotes_50_small {
  width: 150%;
  text-align: left;
  float: left;
  font-size: 3vw;
}

.images_turned_wrapper {
  width: 35vw;
  float: left;
  padding-top: 7vw;
  padding-left: 10vw;
  transform: rotate(10deg);
}

.images_turned_wrapper_left {
  width: 35vw;
  float: left;
  padding-top: 7vw;
  padding-left: 10vw;
  transform: rotate(-10deg);
}

.images_turned_wrapper_100 {
  width: 100vw;
  float: left;
}

.paradigm_title_text {
  width: 90vw;
  text-align: center;
  text-transform: uppercase;
  padding-left: 5vw;
  padding-right: 5vw;
  font-size: 2.5vw;
  position: absolute;
}

.p3_masturbating {
  background: url("masturbating.904e2472.png") 50% / cover;
}

.p3_adam {
  background: url("adam.153d7691.png") 50% / cover;
}

.p3_graph_one {
  background: url("graph_one.f54b9109.png") 50% / cover;
}

.p3_model_one {
  background: url("graph_two.2eb022ba.png") 50% / cover;
}

.p3_model_two {
  background: url("graph_two2.af6e409d.png") 50% / cover;
}

.p3_model_three {
  background: url("graph_two3.ef386116.png") 50% / cover;
}

#s7_title_card {
  width: 100vw;
  height: 100vh;
  background: url("dump_title.5dd9131b.jpg") 50% / cover;
  padding-left: 0;
}

.empty {
  width: 100vw;
  height: 100vh;
  padding-left: 0;
}

#s8_title_card {
  width: 100vw;
  height: 100vh;
  background: url("avantgarde.ce235117.jpg") 50% / cover;
  padding-left: 0;
}

.text_wrapper {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.s8_text {
  width: 90vw;
  height: auto;
  padding-left: 5vw;
  padding-right: 5vw;
}

.s8_backstage {
  background: url("avantgarde_backstage.4a893b09.jpg") 50% / cover;
}

.backstage_images_avantgarde {
  background: url("Backstage_images.da54dbba.png") 50% / cover;
}

.backstage_title {
  color: #000;
  width: 100vw;
  text-align: right;
  box-sizing: border-box;
  padding-top: 4.5vw;
  padding-right: 5vw;
  font-family: everett;
  font-size: 3.5vw;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.s8_video {
  width: auto;
  height: 20%;
  top: 0;
  left: 0;
}

.video_one {
  transform: translate(-10vw, 20vh);
}

.s8_video_horizontal {
  width: auto;
  height: 40%;
  top: 0;
  left: 0;
  transform: translate(20vw, 25vh);
}

.s9_backstage_image {
  width: auto;
  height: 25%;
  top: 0;
  left: 0;
}

.backstage_image_one {
  transform: translate(20vw, 30vh);
}

.backstage_image_two {
  height: 20%;
  transform: translate(0, 55vh);
}

.backstage_images_kitsch {
  background: url("backstage_kitsch_images.8b5ac892.png") 50% / cover;
}

#big_image {
  width: 100%;
  height: auto;
}

.date_text {
  width: 100%;
  color: #c8c8c8;
  text-align: right;
  padding-bottom: 4.5vw;
  padding-left: 5vw;
  padding-right: 5vw;
  font-family: everett;
  font-size: 3.5vw;
  position: absolute;
  bottom: 0;
  right: 0;
}

.container_skip {
  pointer-events: none;
  width: 100vw;
  height: 100%;
  perspective: 1200px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.kitsch_text {
  height: -webkit-fill-available;
  background: url("kitsch_titel.7a8be59e.png") 50% / cover;
}

.continue {
  white-space: nowrap;
  height: 100vw;
  box-sizing: border-box;
  color: #969696;
  opacity: .5;
  padding-top: 93vw;
  display: block;
  transform: rotate(-90deg);
}

.chapter_title {
  width: 100vw;
  text-align: right;
  box-sizing: border-box;
  padding-top: 4.5vw;
  padding-right: 5vw;
  font-family: everett;
  font-size: 3.5vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.p3_last_title {
  text-align: left;
  text-align: left;
  padding-bottom: 4.5vw;
  padding-left: 5vw;
  font-size: 3.5vw;
  top: auto;
  bottom: 0;
  left: 0;
}

h1 {
  text-align: left;
  padding-left: 0;
  font-size: 12vw;
  text-decoration: underline #00f;
}

@media screen and (min-width: 500px) {
  .nav_text {
    font-size: 20px;
  }
}

@media screen and (min-width: 812px) {
  #s01-how-stalin-predicted-tik-tok, #s02-the-new-creative-paradigm, #s03-open-source-is-god, #s04-on-memes, #s05-high-n-low, #s06-referenz, #s07-avantgarde, #s08-kitsch, #s09-love-letter, #s10-further-reading, #s11-credits {
    display: none;
  }

  * {
    color: #646464;
  }

  .date_text {
    display: none;
  }

  .header_container {
    width: 100vw;
  }
}

@media screen and (min-width: 900px) {
  .header {
    color: #646464;
    padding-top: 40px;
    padding-left: 45px;
    display: none;
  }

  .header_desktop {
    color: #646464;
    padding-top: 40px;
    padding-left: 45px;
    display: inline-block;
  }

  .animation_text {
    font-size: 20px;
  }
}

/*# sourceMappingURL=index.1678839f.css.map */
