@font-face {
  src: url("/assets/fonts/everett.otf");
  font-family: "everett";
}

@font-face {
  src: url("/assets/fonts/digi.TTF");
  font-family: "digi";
}

@font-face {
  src: url("/assets/fonts/berthold.ttf");
  font-family: "berthold";
}
